export const isDevelopment = window.location.hostname === 'app-dev.bilgo.se' || window.location.hostname.includes('bilgo-platform-');
export const isProduction = window.location.hostname === 'app.bilgo.se';
export const isTest = process.env.REACT_APP_ENV === 'TEST';

export function getHost() {

    if (isTest) {
        return 'http://localhost:3999'
    }

    if (isProduction) {
        return 'https://api.bilgo.se'
    }
    if (isDevelopment) {
        return 'https://app-alfa.bilgo.se'
    }
    
    return 'https://app-alfa.bilgo.se';
    //return 'http://localhost:3000';
}